.productContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, 270px);
    gap: 2.5rem;
    padding-bottom: 1rem;
    width: 100%;
    justify-content: center;
}
.catContainer{
    background-color: #f9f9f9;;
    min-height: auto;
    max-height: 15rem;
    overflow-y: auto;
    padding-top: 10px;
}
.individualCat{
    display: flex;
    margin-bottom: 10px;
    cursor: pointer;
}
.categoryName{
    font-size: 12px;
}
.individualEmptyBasket {
    line-height: 25px;
    font-size: 14px;
    margin-bottom: 15px;
}

.emptyBasketContainer a {
    width: 15rem;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    height: 64vh;
}
.headerImage{
    height: 100%;
    width: 100%;
}
.emptyBasketContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    height: 64vh;
    width: 100%;
}
.cartImage {
    width: 13rem;
    overflow: hidden;
    height: 13rem;
    margin-bottom: 25px;
}
.checkBox{
    height: 16px;
    width: 16px;
    border: 1px solid lightgray;
    border-radius: 3px;
    margin-right: 7px;
    cursor: pointer;
}
.searchContentText{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
}
.searchContent{
    margin-top:15px;
}
.productFiltres{
    margin-right: 2rem;
    width: 11rem;
}
.productFiltresTitle{
    font-size: 16px;
    font-weight: 600;
}
.productFiltresContent{
    font-size: 14px;
}
.productLayout{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    justify-content: flex-start;
}
.productFiltresContent, .productFiltresTitle{


    padding: 10px 0px;
}
.productFiltresContent:hover{
    cursor:pointer
}
.productFiltresSpan{
    position: relative; /* Ensure positioning context */
    display: inline-block; /* Make the span inline-block */
    width: 6px;
    height: 6px;
    border-bottom: 2px solid #C52E3F;
    border-right: 2px solid #C52E3F;
    transform: rotate(45deg); /* Rotate the span */
    transition: transform 0.3s ease; /* Smooth transition for rotation */
    margin-left: 5px; /* Adjusted margin */
    margin-right: 5px; /* Adjusted margin */
    vertical-align: middle; /* Center vertically with surrounding text */
    margin-left: 5px; /* Adjusted margin */
    margin-right: 5px; /* Adjusted margin */
    vertical-align: middle; /* Center vertically with surrounding text */
}
.rotate {
   transform: rotate(45deg);
}
.productFiltresContent ul li{
    list-style: none;
}
.productFiltresContent ul{
    padding-left: 0px;
    margin: 0px;
}




@media (max-width: 768px) {
    /* CSS rules to apply when screen width is 768 pixels or less */
    .productLayout{
        flex-direction: column;
    }
    .productFiltres, .productContainer{
        width: 100%;
    }
    .productFiltres{

        text-align:left;
    }
    .catContainer{
        margin-bottom: 1rem;
        display:flex;
        flex-wrap: wrap;
        gap: 1rem
    }
  }