.checkoutPage {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}

.pageTitle {
    text-align: center;
    margin-bottom: 20px;
}
.originalPrice {
    text-decoration: line-through;
    color: red;
    font-size: 0.9em;
    margin-right: 5px;
}

.backButton {
    position: absolute;
    left: 180px;
    top: 200px;
    background: none;
    border: none;
    cursor: pointer;
}

.checkoutContainer {
    width: 98%;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;
    overflow-x: auto;
}

.productTable {
    width: 100%;
    border-collapse: collapse;
}

.productTable th,
.productTable td {
    padding: 17px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.productTable th {
    background-color: #f8f8f8;
    font-weight: bold;
}

.bottomContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.observationsContainer {
    width: 100%;
}

.observationsLabel {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

.observationsInput {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    box-sizing: border-box;
}

.totalContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2em;
    font-weight: bold;
}

.placeOrderButton {
    width: 100%;
    padding: 12px 24px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s;
}

.placeOrderButton:hover {
    background-color: #45a049;
}

@media (max-width: 768px) {
    .productTable thead {
        display: none;
    }

    .productTable, .productTable tbody, .productTable tr, .productTable td {
        display: block;
    }

    .productTable tr {
        margin-bottom: 15px;
        border: 1px solid #ddd;
        padding: 10px;
        border-radius: 4px;
    }

    .productTable td {
        text-align: end;
        padding-left: 50%;
        position: relative;
        border-bottom: none;
    }

    .productTable td::before {
        content: attr(data-label);
        position: absolute;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        font-weight: bold;
    }
    .backButton {
        position: static;
        display: block;
        margin-bottom: 20px;
    }

    .checkoutContainer {
        padding: 5px;
    }
}