.searchContainer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

.searchForm {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.searchInput {
    flex: 1;
    border: none;
    padding: 12px 16px;
    font-size: 16px;
    outline: none;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.searchButton {
    height: 100%;
    padding: 0 16px;
    background-color: #C52E3F;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    transition: background-color 0.3s ease;
}

.searchButton:hover {
    background-color: #a62430;
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 9px;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-top: none;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 85%;
}

.dropdownItem {
    padding: 12px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dropdownItem:hover {
    background-color: #f0f0f0;
}

.productTitleLink {
    text-decoration: none;
    color: inherit;
    display: block;
}

@media (max-width: 1024px) {
    .searchButton{
        display: none;
    }
}