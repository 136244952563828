/* Login.module.css */

.loginImage {
    background-image: url('../../../public/misc/login_background.jpg');
    background-size: cover;
    filter: brightness(0.25) hue-rotate(-10deg);
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
}

.loginContainer {
    height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginWhiteContainer {
    box-sizing: border-box;
    height: auto;
    background-color: white;
    width: 26rem;
    border-radius: 2px;
    padding: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7), inset 0 1px 1px rgba(0, 0, 0, 0.1); /* Added box shadow and inset shadow */
}

.loginButton {
    margin-top: auto;
    background-color: #00a825;
    border: 1px solid lightgray;
    color: white;
    font-family: unset;
    border-radius: 3px;
    height: 40px;
    width: 100%;
    transition: background-color 0.4s ease, box-shadow 0.4s ease, transform 0.4s ease;
    position: relative;
    overflow: hidden;
    margin-bottom: 1rem;
}

.loginButton:hover {
    background-color: #00a825;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: scale(1.01);
    cursor: pointer;
}

.loginButton::before {
    content: '';
    position: absolute;
    top: -60%;
    left: -200%;
    width: 300%;
    height: 300%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.5) 10%, rgba(255, 255, 255, 0) 80%);
    transition: transform 0.3s ease;
    z-index: 0;
}

.loginButton:hover::before {
    transform: translate(-50%, -50%);
}

.loginButton > span {
    position: relative;
    z-index: 1;
}

.loginForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
}

.formGroup {
    width: 100%;
    margin-bottom: 1.2rem;
}

.inputField {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 2px;
    font-size: 16px;
    box-sizing: border-box;
    color: rgb(68, 68, 68);
}

.inputField::placeholder {
    color: #999;
}

.inputField:focus {
    border-color: #00a825;
    outline: none;
    box-shadow: 0 0 3px rgba(197, 46, 63, 0.2);
}

.errorMessage {
    color: #c52e3f;
    font-size: 0.875rem;
    margin-top: 0.25rem;
}
.loginWhiteContainer p{
    font-weight: 600;
    color: rgb(68, 68, 68);
    font-size: 17px;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.forgotPassword{
    color: #00a825;
    margin-bottom: 1rem;
}
.redirectContainer{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.redirectContainer >div{
    font-size: 14px;
    color: rgb(68, 68, 68);
}
.redirectContainer >div:hover, .forgotPassword:hover{
    cursor: pointer;
}
@media (max-width: 768px) {
    /* CSS rules to apply when screen width is 768 pixels or less */
    .loginWhiteContainer{
        
        width: 85%;
        max-width: 26rem;
    }
  }