body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;;
}

@font-face {
  font-family: 'Montserrat';
  font-size: 12px;
  src: url('../public/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
/* *{
  font-size: 25px;
} */
h1{
  font-size: 40px;
}
h2{
  padding-top: 1.5rem;
}
h3{
  font-size: 20px;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit color from parent */
  background: none; /* Remove background */
}

/* Optionally, remove other default styles */
a:visited {
  color: inherit; /* Inherit color for visited links */
}

a:hover,
a:focus {
  text-decoration: none; /* Remove underline on hover/focus */
  color: inherit; /* Inherit color on hover/focus */
}

.link {
  text-decoration: none;
  color: inherit;
  background: none;
}

.link:hover,
.link:focus {
  text-decoration: none;
  color: inherit;
}
html {
  scroll-behavior: smooth;
}
.page_title{
  font-size: 23px;
  font-weight: 500;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
