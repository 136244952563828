
.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 6000;
  }
  
  .modalContent {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 30vw;
    min-width: 250px;
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .closeButton {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #888;
    padding: 0px;
    align-self: flex-start;
  }
  
  .modalBody {
    margin-bottom: 10px;
    overflow-y: auto; /* Add scrollbar if content exceeds max height */
    word-wrap: break-word; /* Wrap long words */
  }
  
  .modalFooter {
    display: flex;
    justify-content: flex-end;
  }
  
  .okButton {
    background-color: #C52E3F;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    width: 6rem;
  }
  
  .okButton:hover {
    background-color: #9c1e2b;
  }
  
  .modalHeader h2{
    padding-top: 0px;
    margin: 5px 0px;
  }