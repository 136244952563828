.promotionsContainer{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
}
.individualPromotionContainer{
    padding: 3rem 5rem;
    margin: 2rem;
    background-color: lightgray;
    cursor: pointer;
}