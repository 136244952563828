.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-button-prev {
    color: ;  
}
.swiper-button-next {
    color: white; 
}
.swiper-button-prev::after, .swiper-button-next::after{
    font-size: 13px;
}
  
  /* Styles for navigation arrows */
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 25px; /* Adjust size of the arrow */
    height: 25px; /* Adjust size of the arrow */
    background-color: #C52E3F; /* Background color of the circle */
    color: white; /* Color of the arrow icon */
    border-radius: 50%; /* Creates a circle */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  /* Optional: Hover effect */
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    background-color: black; /* Darker shade for hover effect */
  }

  .swiper-pagination-bullet {
    width: 12px; /* Diameter of the bullet */
    height: 12px; /* Diameter of the bullet */
    background-color: #C52E3F; /* Background color of the bullet */
    border-radius: 50%; /* Creates a circle */
    margin: 0 5px; /* Adjust spacing between bullets */
    cursor: pointer;
  }
  
  /* Optional: Active bullet styles */
  .swiper-pagination-bullet-active {
    background-color: #C52E3F; /* Background color of active bullet */
    border: 1px solid #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Slight shadow effect */
  }
