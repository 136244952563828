.accountContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.accountContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 3rem;
}

.accountTabs {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.accountTabs ul:first-child {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.accountTabs button {
  padding: 15px 15px;
  border: none;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  border-bottom: 1px solid rgba(51, 51, 51, 0.4);
  font-weight: 600;
  background-color: transparent;
}

.accountTabs ul button:hover,
.active {
  border-bottom: 1.5px solid #C52E3F !important;
  color: #C52E3F !important;
}

.dropdownContainer {
  margin-left: auto;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownToggle {
  font-size: 1rem;
  padding: 7px 15px !important;
  cursor: pointer;
  border-radius: 2px;
  color: white !important;
  border: 1px solid #00a825 !important;
  background-color: #00a825 !important;
  display: inline-flex; /* Use inline-flex to fit the content when needed */
  align-items: center;
  justify-content: space-between;
  min-width: 11rem; /* Set the minimum width */
  width: auto; /* Allow width to grow beyond the minimum width if content requires */
}

.arrow {
  width: 6px;
  height: 6px;
  margin-left: 11px;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  transform: rotate(45deg);
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(-135deg);
}

.dropdownToggle .dropdownMenu {
  flex-direction: column !important;
}
.orderItem .orderContainer, .orderItem .accountDetailsContainer{
  border: 1px solid lightgray;
}
.orderItem .accountDetailsContainer{
  padding-left: 10px;
  box-sizing: border-box;
}
.orderItem .orderContainer{
  font-weight: 700;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  display: block;
  min-width: 100%;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  color: #333;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.dropdownMenuItem {
  padding: 10px 20px;
  cursor: pointer;
}

.dropdownMenuItem:hover {
  background-color: #f0f0f0;
}

.sectionTitle {
  font-weight: 700;
}

.accountDetailsContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  padding: 1rem 0px;
}
.contentContainer{
  width: 100%;
}
.sectionContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
  /* Each item takes 50% width, expands as needed */
  gap: 10px;
  /* Adjust the gap as per your design */
  width: 100%;
  /* Ensures the container takes full width available */
  padding: 1rem 0;
  /* Padding for top and bottom */
  box-sizing: border-box;
}

.sectionContainer:last-of-type {
  padding: 1rem 1rem 0 0;

}


.orderContainer {
  padding: 1rem 10px;
}

.sectionContainerTitle {
  font-size: 12px;
}

.orderItem {
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Subtle box shadow */
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  border-radius: 4px;
  margin: 10px 0px;
}

.orderItem:hover {
  cursor: pointer;
}

.sectionContainerContent {
  border-bottom: 1px solid lightgray;
  padding-bottom: 5px;
  cursor: not-allowed;
}

/* ORDERS */
.individualOrderProductContainer {
  display: flex;
  justify-content: flex-start;
  padding: 7px 7px 7px 0px;
  
}
.individualOrderProductContainer >div:first-of-type, .individualOrderProductContainer >div:last-of-type{
  width: 5%;
}
.individualOrderProductContainer >div:nth-of-type(2){
  width: 40%;
}
.individualOrderProductContainer >div{
  width: 25%;
}
.placeOrder {
  position: absolute;
  background-color: #00a825;
  border: 1px solid lightgray;
  color: white;
  font-family: unset;
  border-radius: 3px;
  height: 40px;
  width: 10rem;
  transition: background-color 0.4s ease, box-shadow 0.4s ease, transform 0.4s ease;
  /* Ensure position for light reflection */
  overflow: hidden;
  /* Hide overflow to prevent gradient overflow */
  right: 3%;
  bottom: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.relativeTitle {
  position: relative;
}

.placeOrder:hover {
  background-color: #c52e3f;
  /* Darker background color on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Box shadow for depth effect */
  transform: scale(1.05);
  /* Grow by 5% on hover */
  cursor: pointer;
}

@media (max-width: 1024px) {
  .accountTabs {
    flex-direction: column-reverse;
  }

  .accountTabs>ul {
    flex-direction: column;
    width: 100%;
  }

  .dropdownToggle {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
  }

  .dropdownContainer {
    margin-left: unset;
    width: 100%;
  }

  .dropdown {
    width: 100%;
  }
}