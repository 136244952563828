.footerColorContainer {
    width: 100%;
    background-color: white;
    color: black;
    border-top: 1px solid grey;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

footer {
    background-color: white;
    align-self: flex-end;
    margin-top: auto;
}

.footerContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    border-bottom: 1px solid grey;
    width: 98%;
    padding: 20px;
}

.footerColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.sectionTitle {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 25px;
    font-size: max(15px, min(1vw, 17px));
    font-size: clamp(15px, 16px, 1.8vw);
}

.individualElement {
    margin-bottom: 15px;
    font-size: max(14px, min(1vw, 13px));
    font-size: clamp(13px, 14px, 2vw);
    cursor: pointer;
}

.svgContainer {
    display: flex;
    margin-top: 15px;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.imageContainer {
    width: 9rem;
    height: 50px;
}

.smallSvg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
}

.developedBy {
    text-align: center;
    padding: 10px 0;
    color: grey;
    font-size: 13px;
}

@media (max-width: 1024px) {
    .svgContainer {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width: 768px) {
    .footerContainer {
        padding: 10px;
        grid-template-columns: repeat(1, 1fr);
    }

    .footerColorContainer {
        margin-top: 3rem;
    }

    .sectionTitle {
        margin-bottom: 10px;
    }
}