.homeContentContainer{
    align-self: flex-start;
    padding-top: 25px;
    width: 100%;
}
.homeBorder{
    border-top: 1px solid grey;
    width: 100%;
    padding-bottom: 25px;
}
.productContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
    gap: 15px; /* Space between items */
    padding-bottom: 1rem;
}
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.introSection {
    padding: 40px 20px;
    text-align: center;
    background-color: #f9f9f9;
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1s ease-out;
}

.introTitle {
    color: #333;
    margin-bottom: 20px;
    animation: fadeInUp 0.8s ease-out;
}

.introText {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 20px;
    animation: fadeInUp 1s ease-out;
}

.gepetoLink {
    color: #C52E3F;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s;
}
.gepetoLink2 {
    color: white;
    text-decoration: none;
    transition: color 0.3s;
}

.ctaButton {
    padding: 12px 24px;
    background-color: #00a825;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s, transform 0.3s;
    animation: fadeInUp 1.2s ease-out;
    display: inline-block; /* Ensure the button displays correctly */
}

.ctaButton:hover {
    background-color: #007a1f;
    transform: scale(1.05);
}