.container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    padding-bottom: 10px;
    margin-top: auto;
}

.pageButton {
    margin: 0 3px;
    padding: 8px 14px;
    border: none;
    background-color: #f8f9fa;
    color: #495057;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
    border-radius: 4px;
    font-size: 14px;
    font-family: 'Arial', sans-serif;
}

.pageButton:hover:not(:disabled) {
    color: #212529;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.pageButton:disabled {
    cursor: not-allowed;
    opacity: 0.6;
    background-color: #e9ecef; 
}

.activePage {
    background-color: #8a8b8b;
    color: black;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.activePage:hover{
    color: white !important;
}
