/* src/Breadcrumbs.module.css */
.breadcrumbs {
    list-style: none;
    padding: 0;
}

.bigBread {
    width: 100%;
    margin-right: auto;
    border-bottom: 1px solid lightgray;
}

.breadcrumbs li {
    display: inline;
}

.breadcrumbs li a {
    text-decoration: none;
    color: #C52E3F;
}

.breadcrumbs li:not(:last-child)::after {
    content: " / ";
    padding: 0 6px;
}