/* Basket.module.css */

.basketUpperContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
}

.basketLeftContent {
    width: 100%;
    margin-bottom: 1rem;
}


.basketContainer {
    display: flex;
    flex-direction: column;
}

.rotatableIcon {
    transition: transform 0.4s ease;
}

.rotatableIcon:hover {
    transform: rotate(180deg);
    /* Rotate 45 degrees on hover */
}

.rotatableIconRemove {
    transition: transform 0.2s ease;
    /* Smooth transition for rotation */
}

.rotatableIconRemove:hover {
    transform: rotate(25deg);
    /* Rotate 45 degrees on hover */
}

.basketRow {
    display: grid;
    /* Changed to grid */
    background-color: white;
    margin-bottom: 10px;
    border-bottom: 1px solid lightgray;
    gap: 10px;
    /* Space between grid cells */
    grid-template-columns: 1.4fr 1.5fr 1fr 0.7fr 0.4fr;
    /* Four columns grid layout */
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-height: 10rem;
    /* Elevated shadow on hover */
}

.basketName {
    margin-bottom: 0.5rem;
    font-weight: bold;
    /* Added font-weight for product name */
}

.basketHeader {
    font-weight: bold;
    background-color: white;
}

.basketColumn {
    padding: 25px 0px 25px 0px;
    height: 100%;
    display: flex;
    align-items: center;
    align-self: center;
}
.quantColumn{
    display: flex;
    align-self: center;
    flex-direction: column;
}
.basketColumn:first-of-type {
    padding: 0px;
}

.doubleColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
}

.basketProductImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.placeOrderContainer {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
}

.cartImage {
    width: 13rem;
    overflow: hidden;
    height: 13rem;
}

.individualEmptyBasket {
    line-height: 25px;
    font-size: 14px;
    margin-bottom: 15px;
}

.emptyBasketContainer a {
    width: 15rem;

}

.basketD {
    font-size: 13px;
}

.continueButton {
    margin-top: 35px;
    background-color: #00a825;
    border: 1px solid lightgray;
    color: white;
    font-family: unset;
    border-radius: 3px;
    height: 40px;
    width: 100%;
    transition: background-color 0.4s ease, box-shadow 0.4s ease, transform 0.4s ease;
    position: relative;
    overflow: hidden;
    margin-bottom: 1rem;
    cursor: pointer;
}

.placeOrder {
    margin-top: 25px;
    background-color: #00a825;
    border: 1px solid lightgray;
    color: white;
    font-family: unset;
    border-radius: 3px;
    height: 40px;
    width: 90%;
    transition: background-color 0.4s ease, box-shadow 0.4s ease, transform 0.4s ease;
    /* Updated transition properties */
    position: relative;
    /* Ensure position for light reflection */
    overflow: hidden;
    /* Hide overflow to prevent gradient overflow */
    align-self: center;
}

.removeItemBasket {
    border: 0;
    background-color: transparent;
}

.removeItemBasket:hover {
    cursor: pointer;
}

.emptyBasketContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    height: 64vh;
}

.banderole {
    position: absolute;
    top: 87px;
    /* Adjust as needed */
    left: -14px;
    /* Adjust as needed */
    background: #c52e3f;
    /* Background color for the ribbon */
    color: white;
    border-radius: 2px;
    width: 9rem;

    padding: 15px 0;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    z-index: 10;
    /* Ensure it stays above other content */
    transform: rotate(-45deg) translateY(-20px);
    transform-origin: top left;
    /* Anchor the rotation */
    text-align: center;
    white-space: nowrap;
    /* Prevent text from wrapping */
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

}

.banderoleContainer {
    position: absolute;
    top: -8px;
    left: -8px;
    overflow: hidden;
    width: 150px;
    height: 150px;
    z-index: 10;
}


.banderole::before,
.banderole::after {
    content: "";
    position: absolute;
    top: 100%;
    width: 0;
    height: 0;
}

.banderole::before {
    left: 0;
    border-left: 3px solid #8f2130;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #8f2130;
}
.offerPopup {
    background-color: white;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    z-index: 1000;
}
.iconWrapper{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.banderole::after {
    right: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid #8f2130;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #8f2130;
}

.insideDoubleColumn {
    display: grid;
    /* Added for insideDoubleColumn */
    gap: 5px;
    /* Space between grid cells */
}

.basketTotalPrice {
    align-self: center;
}

.basketRightContent {
    width: 30%;
    height: auto;
    padding: 25px;
    margin-left: 4rem;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.dropdownContainer {
    margin-left: auto;
}

.originalPrice {
    text-decoration: line-through;
    color: red;
    font-size: 0.9em;
    margin-right: 5px;
}

.currentPrice {
    font-weight: bold;
}

.dropdown {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* Box shadow for depth effect */
}

.dropdownToggle {
    padding: 10px 20px;
    width: 100%;
    font-size: clamp(13px, 1vw, 1rem);
    cursor: pointer;
    border-radius: 2px;
    color: white !important;
    border: 1px solid #C52E3F !important;
    background-color: #C52E3F !important;
    display: inline-flex;
    /* Center horizontally */
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.arrow {
    width: 6px;
    height: 6px;
    margin-left: 11px;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    transform: rotate(45deg);
    transition: transform 0.3s ease;
}

.arrow.open {
    transform: rotate(-135deg);
}

.dropdownToggle .dropdownMenu {
    flex-direction: column !important;
}
.basketRow .basketColumn:first-of-type{
    height: 11rem;
    width: 100%;
}

.placeOrder {
    margin-top: 25px;
    background-color: #00a825;
    border: 1px solid lightgray;
    color: white;
    font-family: unset;
    border-radius: 3px;
    height: 40px;
    width: 100%;
    transition: background-color 0.4s ease, box-shadow 0.4s ease, transform 0.4s ease;
    /* Updated transition properties */
    position: relative;
    /* Ensure position for light reflection */
    overflow: hidden;
    /* Hide overflow to prevent gradient overflow */
    align-self: center;
}

.placeOrder:hover {
    background-color: #c52e3f;
    /* Darker background color on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Grow by 5% on hover */
    cursor: pointer;
}

.disabledButton {
    background-color: #d3d3d3;
    /* Light gray background */
    border-color: #a9a9a9;
    /* Darker gray border */
    color: #6c757d;
    /* Gray text color */
    cursor: not-allowed !important;
    /* Forbidden cursor */
    transform: none !important;
    background-color: inherit !important;
}

.removeItemBasket {
    border: 0;
    background-color: transparent;
}

.removeItemBasket:hover {
    cursor: pointer;
}

.dropdownMenu {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1000;
    display: block;
    min-width: 100%;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    color: #333;
    list-style: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.orderTotal {
    margin-top: 10px;
}

.dropdownMenuItem {
    padding: 10px 20px;
    cursor: pointer;
}

.dropdownMenuItem:hover {
    background-color: #f0f0f0;
}

/* Media Query for Mobile Devices */
@media (max-width: 1300px) {
    .basketUpperContainer {
        flex-direction: column;
        width: 100%;
        align-self: center;
    }

    .basketRightContent {
        min-height: 3vw;
        width: 94%;
        margin-left: 0px;
        align-self: center;
    }
}

@media (max-width: 860px) {
    .basketUpperContainer {
        padding-top: 1.5rem;
        flex-direction: column-reverse;
    }
    .basketRightContent{
        max-width: 30rem;
    }
    .basketLeftContent{
        display: flex;
        justify-content: center;
    }
    .basketContainer{
        width: 18rem;
    }
    .basketRightContent {
        padding: 3%;
    }

    .basketProductImage {
        width: 100%;
    }

    .basketRow {
        grid-template-columns: 1fr;

        justify-items: center;
        /* Single column layout on mobile */
    }

    .basketColumn {
        padding: 8px;
        text-align: center;
        align-items: center;
        /* Center text in single column layout */
    }

    .removeItemBasket {
        margin-bottom: 10px;
    }

    .doubleColumn {
        grid-template-rows: auto;
        /* Single row layout on mobile */
    }

    .basketRow {
        margin-bottom: 2rem;
    }
}