.productContainer {
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  height: auto;
  position: relative; /* Ensure relative positioning for the banderole */
  overflow: visible;
}

.banderole {
  position: absolute;
  top: 87px; /* Adjust as needed */
  left: -15px; /* Adjust as needed */
  background: #c52e3f; /* Background color for the ribbon */
  color: white;
  border-radius: 2px;
  width: 9rem;

  padding: 15px 0;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  z-index: 10; /* Ensure it stays above other content */
  transform: rotate(-45deg) translateY(-20px);
  transform-origin: top left; /* Anchor the rotation */
  text-align: center;
  white-space: nowrap; /* Prevent text from wrapping */
  box-shadow: 0 5px 10px rgba(0,0,0,0.1);

}
.offerType {
  position: absolute;
  top: 13.8rem; /* Adjust as needed */
  left: 50%; /* Position the left edge at the center of the parent */
  transform: translateX(-50%); /* Shift the element left by half its own width */
  background: #c52e3f; /* Background color for the ribbon */
  color: white;
  border-radius: 2px;
  width: auto;
  padding: 5px 5px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  z-index: 10; /* Ensure it stays above other content */
  text-align: center;
  white-space: nowrap; /* Prevent text from wrapping */
  box-shadow: 0 5px 10px rgba(0,0,0,0.1);

}
.banderoleContainer {
  position: absolute;
  top: -8px;
  left: -8px;
  overflow: hidden;
  width: 150px;
  height: 150px;
  z-index: 10;
}


.banderole::before,
.banderole::after {
  content: "";
  position: absolute;
  top: 100%;
  width: 0;
  height: 0;
}

.banderole::before {
  left: 0;
  border-left: 3px solid #8f2130;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #8f2130;
}

.banderole::after {
  right: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid #8f2130;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #8f2130;
}

.productContainer:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.02); /* Slight zoom on hover */
}

.productTitleLink {
  text-decoration: none;
  color: inherit;
  display: block;
  width: 100%;
  font-size: 24px;
}

.productPicture {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.productImageContainer{
  width: 100%;
  position: relative;
  height: 10rem;
}
.insideProductContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.productTitle {
  font-size: 16px !important;
  margin-bottom: 1.3rem;
  font-weight: 600;
  text-align: center;
  margin-top: 2rem;
}
.originalPrice {
  text-decoration: line-through;
  color: red;
  font-size: 0.9em;
  margin-right: 5px;
}
.currentPrice {
  font-weight: bold;
}
.productPadding {
  padding-bottom: 8px;
  font-size: 14px;
}


.productPrice {
  color: #00a825;
  font-size: 16px;
  font-weight: 600;
}

.productDiscountContainer {
  display: flex;
  justify-content: center;
  width: 80%;
}

.productInitialPrice {
  font-size: 14px;
  color: #c52e3f;
  text-decoration: line-through;
  margin-right: 4px;
}

.productReducedPercentage {
  background-color: #c52e3f;
  color: white;
  padding: 2px 6px;
  border-radius: 2px;
  font-size: 12px;
}

.quantitySelector {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quantityButton {
  width: 28px;
  height: 28px;
  border: none;
  font-size: 18px;
  cursor: pointer;
  outline: none;
}

.quantityButton.grey {
  background-color: grey;
  color: white;
}

.quantityButton.green {
  background-color: #00a825;
  color: white;
}

.quantityInput {
  width: 40px;
  height: 28px;
  text-align: center;
  border: 1px solid #ccc;
  margin: 0 4px;
  font-size: 14px;
}

.productBasket {
  margin-top: 10px;
  background-color: #00a825;
  border: 1px solid lightgray;
  color: white;
  border-radius: 3px;
  height: 36px;
  width: 100%;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.productBasket:hover {
  background-color: #c52e3f;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.03);
}

.productBasket::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -150%;
  width: 300%;
  height: 300%;
  transition: transform 0.3s ease;
  z-index: 0;
}

.productBasket:hover::before {
  transform: translate(-50%, -50%);
}

.productBasket > span {
  position: relative;
  z-index: 1;
}
