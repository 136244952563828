.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #f9f9f9;
}

.secondContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 80%;
  height: 5rem;
}

.band {
  display: flex;
  flex-grow: 1;
  height: 2rem;
  justify-content: center; /* Centering content horizontally */
  align-items: center; /* Centering content vertically */
  width: 11rem;
}
.band:nth-of-type(2){
  width: 33vw;
}
.band a {
  height: 80%;
}

.band:first-of-type {
  width: 20%;
  height: 5rem;
  min-width: 100px;
  justify-content: flex-start;
  align-items: center;
}

.band:last-of-type {
  justify-content: flex-end;
  align-items: center;
  width: 15%;
  height: 5rem;
}

.bandIcons {
  padding: 5px;
}
.bandIcons:nth-of-type(2){
  padding-right: 6px;
  margin-right: 1rem;
}
.basketIcon {
  margin-right: 20px;
}

.logoButton {
  background: none;
  border: none;
  padding: 0;
  height: 100%;
  width: 100%;
}

.headerImage {
  width: 6rem; /* Ensures image takes full width of its container */
  height: 100%; /* Maintains aspect ratio */
  object-fit: contain; /* Ensures image covers its entire container */
}

.logoButton, .nav {
  padding: 0 !important;
  color: none !important;
  background: none !important;
  border: none !important;
  font: inherit !important;
  cursor: pointer !important;
  outline: inherit !important; /* Ensures buttons are not highlighted when clicked */
  color: black; /* Default color */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.logoButton:hover {
  color: #C52E3F;
}

.menuBand {
  background-color: #C52E3F;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuBand div {
  color: white;
  padding: 0px;
  font-size: 16px; /* Browsers that do not support "MIN () - MAX ()" and "Clamp ()" functions will take this value.*/
  font-size: max(24px, min(1vw, 15px)); /* Browsers that do not support the "clamp ()" function will take this value. */
  font-size: clamp(13px, 16px, 2vw);
}


.menuBandContent {
  justify-content: center;
  display: flex;
  width: 90%;
  position: relative;
}

.menuBand:nth-of-type(2){
  padding: 200px !important;
}
.promotionBand div{
  color: black !important ;
}
.menuButton {
  margin: 0;
  margin: 0px 15px;
  border: none;
  background: none;
  color: white;
  font: inherit;
  line-height: normal;
  cursor: pointer;
  outline: none; /* Ensure no outline on focus */
  /* Additional properties to ensure consistency */
  display: inline-block;
  text-align: center;
  text-decoration: none; /* Remove underline on hover for <a> */
  user-select: none; /* Prevent text selection */
  vertical-align: middle; /* Align vertically */
  -webkit-appearance: none; /* Remove default iOS styling */
  -moz-appearance: none; /* Remove default Firefox styling */
}

.menuButton:hover {
  color: lightgray;
}

.bandIcons:nth-of-type(2) {
  position: relative;
}

.basketHeaderCount {
  align-items: center;
  background-color: #c52e3f;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 12px;
  height: 23px;
  justify-content: center;
  position: absolute;
  right: -16px;
  top: -10px;
  width: 23px;
}
.promotionBand{
  display: block;
  border-bottom: 1px solid rgba(169, 169, 169, 0.5);
  background-color: white;
}
.logoutCountainer {
  list-style: none;
  padding: 8px 1rem;
  background-color: #C52E3F;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  transition: background-color 0.2s;
  box-shadow: 0 0 0 0 transparent;
  transition: box-shadow 0.3s ease;
}

.logoutCountainer:hover {

  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
}
:global(.hide-buttons .swiper-button-prev),
:global(.hide-buttons .swiper-button-next) {
    display: none !important;
}

:global(.hide-buttons .swiper-slide > div:first-child) {
  /* Your custom styles */
  background-color: #f9f9f9 !important;  /* Example style */
}
:global(.hide-buttons .swiper-slide) {
  /* Your custom styles */
  background: #f9f9f9 !important;  /* Example style */
}

@media (max-width: 1024px) {
  .secondContainer {
      width: 90%;
  }

  .container {
      width: 100%;
  }

  .bandIcons:nth-of-type(1), .logoutCountainer {
      display: none;
  }

  .band:first-of-type {
      width: auto;
  }

  .band:first-of-type {
      height: auto;
  }

  .menuBand {
      display: none;
  }


  .mobileMenuToggle {
      display: block;
      cursor: pointer;
  }
  .container {
    position: fixed;
  }

  .mobileMenu {
      z-index: 99999;
      position: fixed;
      top: 5rem;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #f9f9f9;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;
  }

  .mobileMenu.open {
      transform: translateX(0);
  }

  .mobileMenu a, .mobileMenu {
      padding: 1rem;
      font-size: 1rem;
      color: black;
      text-decoration: none;
      border-bottom: 1px solid #eee;
  }
  .logoutButton{
    margin-top: auto !important;
    padding: .7rem;
  }
  .mobileMenu .logoutButton {
      background-color: #C52E3F;
      color: white;
      text-align: center;
      margin-top: 1rem;
      border-radius: 5px;
  }

  .band .hideOnSmallScreens {
      display: none;
  }
}

@media (min-width: 1025px) {
  .mobileMenuToggle {
      display: none;
  }

  .mobileMenu {
      display: none;
  }

  .mobileSearch {
      display: none;
  }
}
