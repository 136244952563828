.individualProductContainer {
  width: 100%;
  display: flex;
  margin-top: 3rem;
}

.leftSide, .rightSide {
  display: flex;
  flex-direction: column;
  height: 30rem;
}

.rightSide {
  width: 40%;
}

.leftSide {
  width: 60%;
  position: relative;
}

.normal, .tva, .nutri {
  margin-bottom: 10px;
}

.normal, .tva {
  margin-top: 1rem;
}

.imageContainer {
  width: 95%;
  border: 1px solid lightgray;
  border-radius: 25px;
  height: 100%;
}

.descriere {
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 1rem;
}

.title {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 1rem;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.productBasket {
  margin-top: 20px;
  background-color: #00a825;
  border: 1px solid lightgray;
  color: white;
  font-family: unset;
  border-radius: 3px;
  height: 40px;
  width: 100%;
  transition: background-color 0.4s ease, box-shadow 0.4s ease, transform 0.4s ease;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.productPicture {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.quantityContainer {
  margin-top: auto;
}

.sectionContainerTitle {
  font-size: 12px;
}
.secondDescription{
  white-space: pre-line;
}
.sectionContainerContent {
  white-space: pre-line;
  line-height: 23px;
  max-height: 12rem;
  overflow-y: auto;
  padding-top: 5px;
}

.underContainer {
  margin-top: 2rem;
  width: 100%;
  padding-bottom: 1rem;
}

.accountTabs {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.accountTabs ul:first-child {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.accountTabs button {
  padding: 5px 15px;
  border: none;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  border-bottom: 1px solid rgba(51, 51, 51, 0.4);
  font-weight: 600;
  background-color: transparent;
}

.accountTabs ul button:hover,
.active {
  border-bottom: 1.5px solid #C52E3F !important;
  color: #C52E3F !important;
}

/* New styles for the offer field */
.oferta {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f8f8f8;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}

.rotatableIcon{
  transition: transform 0.4s ease;
  margin-right: 1rem;

}
.rotatableIcon:hover {
  transform: rotate(180deg);
  /* Rotate 45 degrees on hover */
}
.offerTypeContainer {
  flex-grow: 1;
}

.offerTypeTitle {
  font-weight: bold;
  font-size: 1.1em;
  color: #C52E3F;
  margin-bottom: 0.5rem;
}

.offerList {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.offerList li {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.flatDiscount {
  font-weight: bold;
}





/* BANDEROLE */
.banderole {
  position: absolute;
  top: 98px;
  left: -15px;
  background: #c52e3f;
  color: white;
  border-radius: 2px;
  width: 10rem;
  padding: 15px 0;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  z-index: 10;
  transform: rotate(-45deg) translateY(-20px);
  transform-origin: top left;
  text-align: center;
  white-space: nowrap;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

}
.banderoleContainer {
  position: absolute;
  top: -6px;
  left: -6px;
  overflow: hidden;
  width: 150px;
  height: 150px;
  z-index: 10;
}


.banderole::before,
.banderole::after {
  content: "";
  position: absolute;
  top: 100%;
  width: 0;
  height: 0;
}

.banderole::before {
  left: 0;
  border-left: 3px solid #8f2130;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #8f2130;
}

.banderole::after {
  right: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid #8f2130;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #8f2130;
}





@media (max-width: 1024px) {
  .individualProductContainer {
      flex-direction: column;
      margin-bottom: 20px;
  }
  .normal{
    text-align: center;
  }
  .leftSide, .rightSide {
      width: 100%;
  }
  .rightSide {
      justify-content: center;
      align-items: center;
      height: auto;
  }
  .quantityContainer {
      width: 100%;
      margin-top: 2rem;
  }
  .imageContainer {
      width: 100%;
  }
  .descriere {
      text-align: center;
  }
  .title {
      margin-top: 15px;
  }
}

@media (max-width: 768px) {
  /* Add any specific styles for screens up to 768px wide */
  .leftSide{
    height: 17rem;
  }
  .sectionContainerContent {
    line-height: 23px;
    max-height: 100%;
    overflow-y: unset;
  }
}