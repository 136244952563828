.layoutContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    align-items: center;
    overflow-x: hidden;
    position: relative;
}
#root{
    display: flex !important;
}
.contentContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    flex: 1;
}

@media (max-width: 1024px) {
.contentContainer{
    width: 90%;
    align-self: center;
    padding-top: 5rem;
}
}
