.contactContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    background-color: #f9f9f9;
    color: #333;
}

.title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #C52E3F;
}

.content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    width: 100%;
    margin-bottom: 3rem;
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start ;
    padding: 20px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.infoItem {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.infoItem a, .infoItem .infoText{
    margin: 0px 0px 0px 10px;
}
.icon {
    font-size: 2rem;
    margin-right: 15px;
    color: #C52E3F;
}

.infoText {
    font-size: 15px;
    color: #333;
}

.formContainer {
    padding: 20px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formContainer form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formGroup {
    margin-bottom: 20px;
    width: 100%;
}

.label {
    font-size: 1rem;
    margin-bottom: 5px;
    color: #333;
}

.input, .textarea {
    width: 99%;
    padding: 10px 0px 10px 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    color: #333;
}
.input:focus, .textarea:focus{
    outline: none;
}
.input{
    cursor: not-allowed;
}
.textarea {
    resize: vertical;
    height: 150px;
}

.button {
    padding: 15px;
    background-color: #C52E3F;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 7rem;
}

.button:hover {
    background-color: #a62430;
}
.input, .textarea{
    margin-top: 7px;
}

@media (max-width: 768px) {
    .content {
        grid-template-columns: 1fr;
    }
}
